<template>
    <CContainer class="d-flex align-items-center min-vh-100">
        <CRow class="w-100 justify-content-center">
            <CCol md="6">
                <div class="w-100">
                    <div class="clearfix">
                        <h1 class="float-left display-3 mr-4">403</h1>
                        <h4 class="pt-3" v-translate>Forbidden</h4>
                        <p class="text-muted" v-translate>You are not authorized to access the requested page.</p>
                    </div>

                    <router-link :to="{name: 'eventList'}">
                        <CButton color="primary"><translate></translate>Go back to the main page</CButton>
                    </router-link>
                </div>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
    export default {
        name: 'Page403'
    }
</script>
